import React from "react";
import { Link } from "gatsby";

import poetBG from "../images/poetBG.png";
import TextWrapAroundImg from "../components/textWrapAroundImg";
import Head from "../components/head";
import poetStyles from "./poetsCorner.module.css";

const PoetsCornerPage = () => {
  return (
    <>
    <Head title="Poets corner"></Head>
      <section className={poetStyles.blogSection}>
        <div
          className={poetStyles.BlogBGImage}
          style={{
            height: "70vh",
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${poetBG})`,
          }}
        >
          <div>
            <h1 className={poetStyles.heading}>Poets' Corner</h1>
            <p className={poetStyles.subHeading}>
              Poetry from Athen's Gerogia Local Bob Ambrose.
            </p>
          </div>
        </div>

        {/* <div className={poetStyles.blogCopyContainer}>
          <div>
            <h3>Recent Poem</h3>
            <h2>The Comfort of Fading</h2>
            <p>
              There were years of prime
              <br /> when I looked upon a living bay
              <br /> pulsing with life, pulled
              <br /> by celestial bodies, and saw
            </p>
            <Link to="/blog/blogTemplate">Read more</Link>
          </div>
        </div> */}
      </section>

      <TextWrapAroundImg
        imgRight={false}
        paragraphHeader={false}
        lightBackground={false}
      >
        <p>
          The building of everything TSS, Inc. is attempting to do around the
          mystique of Robert Frost's short poem establishes poetry too, in
          addition to music, as integral in our institutional span of interests.
          Like music, we take poetry as one the ways the human organism has
          evolved to encompass and communicate about the less objective of
          reality's two physical and phenomenal phases. We are fortunate indeed
          to have attracted the interest in what we do of an Athens, GA poet,
          Robert Ambrose, Jr., who is also an EPA (US Environmental Protection
          Agency) environmental engineer well practiced in the applied-science
          use of mathematics to address complex systems problems involving the
          interactions of man and nature. And mathematics we take as the most
          formal expression of humanity's contribution to phenomenal reality.
          For present purposes our own 'poet laureate' has directed us to his
          website, where he introduces his poetry he recited at Marie's Memorial
          Service and Concert on the first anniversary of her funeral, Sunday,
          July 30, 2017. In 'Seasons' he writes of "young love that attracts and
          binds two strangers", then of "love that bonds beyond death." In
          'Interlude' he observes that "Every life is singular. But every life
          plays out universal themes." The recitations at Marie's anniversary
          memorial were a moving and memorable event, and they retain these
          qualities here in this first edition of what we hope will become a
          perennial contribution to what we do.
        </p>
        <div style={{ textAlign: "center" }}>
          <a href="https://bobambrosejr-poetry.blogspot.com/p/the-secret-sits.html">
            https://bobambrosejr-poetry.blogspot.com/p/the-secret-sits.html
          </a>
        </div>
        <div style={{ textAlign: "center" }}>
          <a href="https://bobambrosejr-poetry.blogspot.com/p/tss.html">
            Graveside: “Seasons – unfolding and enfolding”
          </a>
        </div>
        <div style={{ textAlign: "center" }}>
          <a href="https://bobambrosejr-poetry.blogspot.com/p/tss-memorial-service-sextons-house.html">
            The Sexton’s House: “Interlude: The Poet’s Traverse”
          </a>
        </div>
      </TextWrapAroundImg>
    </>
  );
};

export default PoetsCornerPage;
